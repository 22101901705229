import { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetSides, AddSubject } from "../../redux/apiCalls/subscribersApiCall";
import { Input } from "../Input/Input";
import { Materials } from "../../utils/arrays";
import Select from "react-select";
import Loading from "../Loading";
export const FormAddSubject = () => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "1px solid #004556",
      borderRadius: "15px",
      minHeight: "46px",
      "&:hover": {
        border: "1px solid #004556",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#004556" : "white",
      color: state.isSelected ? "white" : "#004556",
      "&:hover": {
        backgroundColor: state.isSelected ? "#004556" : "#f2f2f2",
      },
    }),
  };
  // Custom styles for react-select
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSide, setSelectedSide] = useState([]);
  const addSubject = useRef("");
  const [state, setState] = useState(false);
  const sides = useSelector((state) => state.users.sides);
  const loading = useSelector((state) => state.users.delete);
  useEffect(() => {
    dispatch(GetSides());
  }, [dispatch]);
  useEffect(() => {
    if (state) {
      navigate("/");
    }
  }, [state, navigate]);
  const handelAddMaterial = async (e) => {
    e.preventDefault();
    const idSide = selectedSide.map((side) => side.value);
    const name = addSubject.current.material.value;
    const questionDuration = addSubject.current.time.value;
    const infoSubject = {
      name: name,
      questionDuration: questionDuration,
      side: idSide,
    };
    dispatch(AddSubject(setState, infoSubject));
  };
  return (
    <Fragment>
      <div className="material-add w-full flex items-start gap-[120px] bg-[white]  py-[70px] px-[80px] rounded-[15px] mt-[43px]">
        <div className="inputs flex-1">
          <form
            className="flex flex-col gap-[35px]"
            onSubmit={handelAddMaterial}
            ref={addSubject}
          >
            <div className="box-input">
              <label
                className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                htmlFor="اسم الفرع"
              >
                اسم الفرع
              </label>
              <Select
                isMulti
                classNamePrefix="react-select"
                styles={customStyles}
                id="اسم الفرع"
                options={sides.map((side) => ({
                  label: side.name,
                  value: side._id,
                }))}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map(
                    (option) => option
                  );
                  setSelectedSide(selectedValues);
                }}
                value={selectedSide.label}
                placeholder=""
              />
            </div>
            {Materials.map((material) => {
              return (
                <Input
                  key={material.id}
                  for={material.label}
                  label={material.label}
                  type={material.type}
                  id={material.label}
                  name={material.name}
                />
              );
            })}
            <button
              className=" text-[white] group w-full py-[14px]  relative z-10 shadow-shadow rounded-[15px]"
              disabled={loading}
            >
              <span className="bg-main group-hover:scale-[1.01] transition duration-[0.2s] absolute left-0 top-0 w-full h-full rounded-[15px] z-[-2]"></span>
              {loading ? <Loading /> : "حفظ"}
            </button>
          </form>
        </div>
        <div className="w-[280px] h-[252px] sticky top-[10px]">
          <img
            className="w-full h-full"
            src="/assests/imageInput/person.svg"
            alt=""
          />
        </div>
      </div>
    </Fragment>
  );
};
