import { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Input } from "../Input/Input";
import { Materials } from "../../utils/arrays";
import { GetSides, EditSubject } from "../../redux/apiCalls/subscribersApiCall";
import { request } from "../../utils/request";
import Loading from "../Loading";
import Select from "react-select";

export const FormEditSubject = () => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "1px solid #004556",
      borderRadius: "15px",
      minHeight: "46px",
      "&:hover": {
        border: "1px solid #004556",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#004556" : "white",
      color: state.isSelected ? "white" : "#004556",
      "&:hover": {
        backgroundColor: state.isSelected ? "#004556" : "#f2f2f2",
      },
    }),
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const editsubject = useRef("");
  const dispatch = useDispatch();
  const [state, setState] = useState(false);
  const [SideID, setSideID] = useState("");
  const [selectedSide, setSelectedSide] = useState([]);
  const GetSubjectsByID = async (ID) => {
    try {
      const response = await request.get(`/subjects/${ID}`);
      const data = response.data;
      const initialSelectedValue = {
        label: data.subject.sideName,
      };
      setSideID(data?.subject.side);
      setSelectedSide(initialSelectedValue);
      editsubject.current.material.value = data?.subject.name;
      editsubject.current.time.value = data?.subject.questionDuration / 60;
    } catch (error) {
      console.log(error);
    }
  };
  const sides = useSelector((state) => state.users.sides);
  const loading = useSelector((state) => state.users.delete);
  useEffect(() => {
    if (state) {
      navigate("/");
    }
  }, [state, navigate]);
  useEffect(() => {
    GetSubjectsByID(id);
  }, [id]);
  useEffect(() => {
    dispatch(GetSides());
  }, [dispatch]);
  const handelEditSubject = async (e) => {
    e.preventDefault();
    const idSide = SideID;
    const name = editsubject.current.material.value;
    const questionDuration = editsubject.current.time.value;
    const infoSubjectEdit = {
      side: idSide,
      name: name,
      questionDuration: questionDuration,
    };
    dispatch(EditSubject(setState, id, infoSubjectEdit));
  };
  const sideOptions = sides.map((side) => ({
    value: side.id,
    label: side.name, // Assuming 'name' is the property that holds the side name
  }));
  return (
    <Fragment>
      <div className="material-add w-full flex items-start gap-[120px] bg-[white]  py-[70px] px-[80px] rounded-[15px] mt-[43px]">
        <div className="inputs flex-1">
          <form
            className="flex flex-col gap-[35px]"
            onSubmit={handelEditSubject}
            ref={editsubject}
          >
            <div className="box-input">
              <label
                className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                htmlFor="اسم الفرع"
              >
                اسم الفرع
              </label>
              <Select
                isMulti
                classNamePrefix="react-select"
                styles={customStyles}
                id="اسم الفرع"
                placeholder=""
                value={selectedSide}
                options={sideOptions}
                onChange={(selectedOptions) => {
                  setSelectedSide(selectedOptions); // Update the selected options state
                  if (selectedOptions) {
                    const selectedValues = selectedOptions
                      .filter((option) => option.value !== undefined)
                      .map((option) => option.value);
                    setSideID((prevSideID) => {
                      // Concatenate the previous side IDs with the new ones
                      return [...(prevSideID || []), ...selectedValues];
                    });
                  } else {
                    setSideID(""); // Reset if no options are selected
                  }
                }}
              />
            </div>
            {Materials.map((material) => {
              return (
                <Input
                  key={material.id}
                  for={material.label}
                  label={material.label}
                  type={material.type}
                  id={material.label}
                  name={material.name}
                />
              );
            })}
            <button className=" text-[white] group w-full py-[14px]  relative z-10 shadow-shadow rounded-[15px]">
              <span className="bg-main group-hover:scale-[1.01] transition duration-[0.2s] absolute left-0 top-0 w-full h-full rounded-[15px] z-[-2]"></span>
              {loading ? <Loading /> : "حفظ"}
            </button>
          </form>
        </div>
        <div className="w-[280px] h-[252px] sticky top-[10px]">
          <img
            className="w-full h-full"
            src="/assests/imageInput/person.svg"
            alt=""
          />
        </div>
      </div>
    </Fragment>
  );
};
