import React, { useState } from "react";
import Alert from "../Alert";
import Pop from "../Pop";
export default function NotificationCard(props) {
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full flex justify-between p-[25px_25px] border-[2px] h-[102px] rounded-[15px] bg-[white] border-[#00B0DC1A] mb-[18px]">
      <Alert
        open={open}
        setOpen={setOpen}
        title="حذف الاشعار!"
        buttonTitle="تأكيد"
        img="/assests/AlertDeleteSubject.svg"
        paragraph="هل أنت متأكد من حذف الاشعار؟"
        onclick={() => props.ondelete()}
      />
      <Pop open={open} setOpen={setOpen} />
      <div className="flex flex-col justify-evenly ]">
        <h3 className="text-main font-bold text-[16px] mb-[16px]">
          {props.title}
        </h3>
        <p className="text-[14px] text-darkBlue ">{props.body}</p>
      </div>
      <div className="flex flex-col justify-between items-end">
        <p className="ltr text-[14px] mb-[20px]">{props.createdAt}</p>
        <img
          src="/assests/deleteIcon.svg"
          onClick={() => setOpen(true)}
          className="w-[19px] cursor-pointer h-[19px]"
          alt=""
        />
      </div>
    </div>
  );
}
