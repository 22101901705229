import React, { useState } from "react";
import Alert from "../Alert";
import Pop from "../Pop";
import { ThemeProvider, createTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
const theme = createTheme({
  typography: {
    fontFamily: "Tajawal, sans-serif", // Change the font family here
    fontSize: 18, // Change the font size here
  },
});
export default function ChatCards(props) {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="subjectCard p-[18px] w-[272px] h-[226px] relative rounded-[15px] shadow-shadow bg-[white] flex flex-col gap-[20px]"
      id={props.ID}
    >
      <Alert
        open={open}
        setOpen={setOpen}
        title="حذف الرسالة!"
        buttonTitle="تأكيد"
        img="/assests/AlertDeleteSubject.svg"
        paragraph="هل أنت متأكد من حذف الرسالة؟"
        onclick={() => props.ondelete()}
      />
      <Pop open={open} setOpen={setOpen} />
      <div className="flex items-center gap-[10px]">
        <p className="text-[16px] font-[500]">من</p>
        <p className="text-[16px] font-[500] text-main">{props.username}</p>
      </div>
      <ThemeProvider theme={theme}>
        <Tooltip title={props.title} TransitionComponent={Zoom}>
          <div className="flex flex-col items-start text-ellipsis overflow-hidden ...">
            <p className="text-[16px] font-[500]">المشكلة:</p>
            <p className="text-[16px] font-[500] text-main text-ellipsis overflow-hidden ...">
              {props.title}
            </p>
          </div>
        </Tooltip>
      </ThemeProvider>
      <div className="flex flex-col items-start">
        <p className="text-[16px] font-[500]">رقم الموبايل</p>
        <p className="text-[16px] font-[500] text-main">{props.phoneNumber}</p>
      </div>
      <div className="flex justify-end">
        <img
          src="/assests/deleteIcon.svg"
          onClick={() => setOpen(true)}
          className="w-[19px] cursor-pointer h-[19px] absolute left-[20px] bottom-[20px]"
          alt=""
        />
      </div>
    </div>
  );
}
