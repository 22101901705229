import React, { useState, useEffect } from "react";
import { HeadInputs } from "../components/HeadInputs/HeadInputs";
import { useSelector, useDispatch } from "react-redux";
import NotificationCard from "../components/Notification/NotificationCard";
import PaginationCom from "../components/Pagination";
import Pop from "../components/Pop";
import AddNotificationToAll from "../components/Notification/AddNotificationToAll";
import {
  GetNotifcation,
  DeleteNotification,
} from "../redux/apiCalls/subscribersApiCall";
import { format } from "date-fns";
import { NOTAFICATION } from "../utils/arrays";
import Loading from "../components/Loading";

const Notification = () => {
  const [open2, setOpen2] = useState(false);
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.users.notifications);
  const loading = useSelector((state) => state.users.loading);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  useEffect(() => {
    dispatch(GetNotifcation(page, NOTAFICATION)).then((re) => {
      setCount(Math.ceil(re.documentCount / NOTAFICATION));
    });
  }, [dispatch, page]);
  const ClickDleteNotification = async (ID) => {
    dispatch(DeleteNotification(ID)).then(() => {
      dispatch(GetNotifcation(page, NOTAFICATION)).then((re) => {
        if (re.notifications.length < 1) {
          dispatch(GetNotifcation(page - 1, NOTAFICATION));
        }
        setCount(Math.ceil(re?.documentCount / NOTAFICATION));
      });
    });
  };
  return (
    <div className="bg-bg w-[100%] h-full">
      <AddNotificationToAll open={open2} setOpen={setOpen2} />
      <Pop open={open2} setOpen={setOpen2} />
      <div className="flex justify-between mb-[20px] items-center">
        <div className="flex gap-[21px]">
          <HeadInputs title="الإشعارات" />
        </div>
        <span
          onClick={() => setOpen2(true)}
          className="text-main text-[18px] underline cursor-pointer"
        >
          إضافة إشعار جديد
        </span>
      </div>
      {loading ? (
        <div className="h-full w-full flex items-center justify-center">
          <Loading color="#004556" size="24" />
        </div>
      ) : notification.length === 0 ? (
        <p className="flex justify-center font-[600] text-[24px]">
          لايوجد اشعارات . . .
        </p>
      ) : (
        <div className="flex flex-col">
          <div>
            {notification?.map((note) => {
              return (
                <NotificationCard
                  title={note?.title}
                  body={note?.body}
                  createdAt={
                    note?.createdAt
                      ? format(new Date(note.createdAt), "dd/MM/yyyy")
                      : "N/A"
                  }
                  ID={note?._id}
                  ondelete={() => ClickDleteNotification(note._id)}
                />
              );
            })}
          </div>
          <PaginationCom page={page} setPage={setPage} count={count} />
        </div>
      )}
    </div>
  );
};

export default Notification;
