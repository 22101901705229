import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetCenter, DeleteCenter } from "../redux/apiCalls/subscribersApiCall";
import { CenterCard } from "./../components/CenterCard/CenterCard";
import Loading from "../components/Loading";
import { PER_PAGE } from "../utils/arrays";

import PaginationCom from "../components/Pagination";
import DrobCenters from "../components/CenterCard/DrobCenters";
import { HeadInputs } from "../components/HeadInputs/HeadInputs";

export const CenterSales = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [countryF, setCountryF] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  useEffect(() => {
    dispatch(GetCenter(page, PER_PAGE, countryF)).then((re) => {
      setCount(Math.ceil(re?.documentCount / PER_PAGE));
    });
  }, [dispatch, page, countryF]);

  const centers = useSelector((state) => state.users.centers);
  const loading = useSelector((state) => state.users.loading);
  const navigate = useNavigate();
  const ClickDeleteCenter = async (ID) => {
    dispatch(DeleteCenter(setOpen, ID)).then(() => {
      dispatch(GetCenter(page, PER_PAGE, countryF)).then((re) => {
        if (re.center.length < 1) {
          dispatch(GetCenter(page - 1, PER_PAGE, countryF));
        }
        setCount(Math.ceil(re?.documentCount / PER_PAGE));
      });
    });
  };
  const country = [
    "كل المحافظات",
    "حلب",
    "الرقة",
    "دير الزور",
    "الحسكة",
    "حماة",
    "اللاذقية",
    "طرطوس",
    "حمص",
    "دمشق",
    "السويداء",
    "القنيطرة",
    "درعا",
  ];
  return (
    <Fragment>
      <HeadInputs title="مراكز البيع" />

      <div className="flex justify-between items-center my-[20px]">
        <DrobCenters
          label={"المحافظة"}
          text={country}
          setCountryF={setCountryF}
        />
        <span
          className="text-main text-[18px] underline cursor-pointer"
          onClick={() => navigate("/addCenter")}
        >
          إضافة مركز جديدة
        </span>
      </div>
      {loading ? (
        <div className="h-full w-full flex items-center justify-center">
          <Loading color="#004556" size="24" />
        </div>
      ) : centers.length === 0 ? (
        <p className="flex justify-center font-[600] text-[24px]">
          لايوجد مراكز . . .
        </p>
      ) : (
        <div className="flex flex-col">
          <div className="cardsLessons grid grid-cols-3 gap-[40px_60px]">
            {centers.map((center) => {
              return (
                <CenterCard
                  key={center._id}
                  name={center.name}
                  governorate={center.governorate}
                  address={center.address}
                  phoneNumber={center.phoneNumber}
                  ID={center._id}
                  open={open}
                  ondelete={() => ClickDeleteCenter(center._id)}
                />
              );
            })}
          </div>
          <PaginationCom page={page} setPage={setPage} count={count} />
        </div>
      )}
    </Fragment>
  );
};
