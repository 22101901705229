import React, { Fragment, useEffect, useState } from "react";
import ChatCards from "../components/Chats/ChatCards";
import { useDispatch, useSelector } from "react-redux";
import {
  GetMessage,
  DeleteMessage,
} from "../redux/apiCalls/subscribersApiCall";
import PaginationCom from "../components/Pagination";
import { PER_PAGE } from "../utils/arrays";
import Loading from "../components/Loading";
import { HeadInputs } from "../components/HeadInputs/HeadInputs";
export default function Messages() {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.users.messages);
  const loading = useSelector((state) => state.users.loading);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  useEffect(() => {
    dispatch(GetMessage(page, PER_PAGE)).then((re) => {
      setCount(Math.ceil(re.documentCount / PER_PAGE));
    });
  }, [dispatch, page]);
  const ClickDleteMessage = async (ID) => {
    dispatch(DeleteMessage(ID)).then(() => {
      dispatch(GetMessage(page, PER_PAGE)).then((re) => {
        if (re.message.length < 1) {
          dispatch(GetMessage(page - 1, PER_PAGE));
        }
        setCount(Math.ceil(re?.documentCount / PER_PAGE));
      });
    });
  };
  return (
    <Fragment>
      <HeadInputs title="الرسائل" />
      <div className="flex flex-col h-full">
        {loading ? (
          <div className="h-full w-full flex items-center justify-center">
            <Loading color="#004556" size="24" />
          </div>
        ) : messages.length === 0 ? (
          <p className="flex justify-center font-[600] text-[24px]">
            لايوجد رسائل . . .
          </p>
        ) : (
          <div className="flex flex-col h-full">
            <div className="cardsLessons grid grid-cols-3 gap-[40px_60px] mt-[43px]">
              {messages?.map((message) => {
                return (
                  <ChatCards
                    username={message?.username}
                    title={message?.title}
                    phoneNumber={message?.phoneNumber}
                    ID={message?._id}
                    ondelete={() => ClickDleteMessage(message._id)}
                  />
                );
              })}
            </div>
            <PaginationCom page={page} setPage={setPage} count={count} />
          </div>
        )}
      </div>
    </Fragment>
  );
}
